import React, { Component } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image'
import styled from 'styled-components';
import { Container, Jumbotron } from 'styled-bootstrap-components';
import MediaQuery from 'react-responsive';

import FeaturedAlbum from './FeaturedAlbum';
import ScrollArrow from './ScrollArrow';
import theme from '../../styled/theme';

const Wrapper = styled.div`
  background: ${theme.colors.black};
  position: relative;
  height: calc(100vh - 90px);
  overflow: hidden;

  @media (max-width: 767px) {
    text-align: center;
  }
`;

const StyledJumbotron = styled(Jumbotron)`
  background: transparent;
  box-sizing: border-box;
  height: 100%;
  padding: 5rem 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
`;

const Signature = styled(Img)`
  margin: 2rem 0 0 5rem;
`;

class Hero extends Component {
  state = {};

  render() {
    return (
      <StaticQuery
        query={graphql`
          query featuredAlbum {
            allWordpressWpAlbum(sort: { fields: acf___release_date, order: DESC }, limit: 1) {
              edges {
                node {
                  id
                  title
                  featured_media {
                    alt_text
                    localFile {
                      childImageSharp {
                        resolutions(width: 180, height: 180) {
                          ...GatsbyImageSharpResolutions
                        }
                      }
                    }
                  }
                  acf {
                    album_type
                    streaming_platforms {
                      platform
                      url
                    }
                    purchase_platforms {
                      platform
                      url
                    }
                  }
                }
              }
            }
          }
        `}
        render={data => (
          <Wrapper>
            <MediaQuery minDeviceWidth={768}>
              <Img sizes={this.props.image.localFile.childImageSharp.sizes} />
            </MediaQuery>
            <StyledJumbotron>
              <Container>
                {data.allWordpressWpAlbum.edges.map(album => <FeaturedAlbum key={album.node.id} album={album.node} />)}
                <MediaQuery minDeviceWidth={768}>
                  <Signature resolutions={this.props.signature.localFile.childImageSharp.resolutions} />
                </MediaQuery>
              </Container>
              <ScrollArrow target="videos" />
            </StyledJumbotron>
          </Wrapper>
        )}
      />
    );
  }
}

export default Hero;
