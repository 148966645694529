import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { Container, Row, Column } from 'styled-bootstrap-components';

import Layout from '../components/Layout';
import Hero from '../components/Hero';
import VideoPlayer from '../components/VideoPlayer';
import theme from '../styled/theme';

const Section = styled.section`
  padding: 4rem 0;
`;

const SectionTitle = styled.div`
  margin-bottom: 2rem;

  h1 {
    color: ${theme.colors.grayDark};
    font-size: 2.25rem;
    letter-spacing: 2.25px;
    margin: 0;
    text-transform: uppercase;
  }

  p {
    color: ${theme.colors.gray};
    font-size: 1.125rem;
  }
`;

const IndexPage = ({ data, location }) => {
  const image = data.wordpressPage.featured_media;
  const { signature } = data.wordpressPage.acf;
  return (
    <Layout location={location}>
      <Hero image={image} signature={signature} />
      <Container>
        <Row align="center" justifyContent="center">
          <Column sm={10}>
            <Section id="videos">
              <SectionTitle>
                <h1>Videos Recientes</h1>
                <p>Disfruta de mis videos musicales más recientes. Visita nuestro canal de YouTube para ver más.</p>
              </SectionTitle>
              <VideoPlayer videos={data.allYoutubeVideo.edges} />
            </Section>
          </Column>
        </Row>
      </Container>
    </Layout>
  );
};

export const query = graphql`
  query IndexPageQuery {
    wordpressPage(slug: { eq: "inicio" }) {
      title
      featured_media {
        alt_text
        localFile {
          childImageSharp {
            sizes(maxWidth: 1440) {
              ...GatsbyImageSharpSizes
            }
          }
        }
      }
      acf {
        signature {
          alt_text
          localFile {
            childImageSharp {
              resolutions(width: 180, height: 76) {
                ...GatsbyImageSharpResolutions
              }
            }
          }
        }
      }
    }
    allYoutubeVideo(limit: 4) {
      edges {
        node {
          id
          title
          description
          videoId
          publishedAt
          privacyStatus
          thumbnail {
            url
          }
        }
      }
    }
  }
`;

export default IndexPage;
