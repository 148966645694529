import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import styled from 'styled-components';
import { Container, Row, Column } from 'styled-bootstrap-components';

import theme from '../../styled/theme';
import { FlexRow } from '../../styled';
import Service from '../Album/Service';

const Wrapper = styled.div`
  color: white;
  padding: 2rem;

  @media (min-width: 768px) {
    background-color: rgba(255, 255, 255, 0.03);
    width: 50%;
  }

  @media (max-width: 767px) {
    ${FlexRow} {
      justify-content: center;
    }
  }
`;

const Title = styled.h1`
  font-size: 2.25rem;
  letter-spacing: 2px;
  margin: 0 0 0.5rem 0;
  text-transform: uppercase;
`;

const Subtitle = styled.div`
  color: ${theme.colors.primary};
  font-size: 0.85rem;
  letter-spacing: 1px;
  text-transform: uppercase;

  @media (max-width: 767px) {
    margin-top: 1rem;
  }
`;

const FeaturedAlbum = props => (
  <Wrapper>
    <Container>
      <Row>
        <Column sm={4}>
          <Img resolutions={props.album.featured_media.localFile.childImageSharp.resolutions} />
        </Column>
        <Column sm={8}>
          <Subtitle>Escucha mi nuevo {props.album.acf.album_type}</Subtitle>
          <Title>{props.album.title}</Title>
          <FlexRow>
            {props.album.acf.streaming_platforms.map(platform => (
              <Service key={platform.platform} platform={platform} />
            ))}
          </FlexRow>
        </Column>
      </Row>
    </Container>
  </Wrapper>
);

FeaturedAlbum.propTypes = {
  album: PropTypes.shape({
    title: PropTypes.string,
    acf: PropTypes.object,
    featured_media: PropTypes.object,
  }),
};

export default FeaturedAlbum;
