import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import * as Scroll from 'react-scroll';
import theme from '../../styled/theme';
import { Text } from '../../styled';

const { Link } = Scroll;

const Wrapper = styled.div`
  cursor: pointer;
  position: absolute;
  left: 50%;
  bottom: 45px;
  transform: translateX(-50%);
`;

const Triangle = styled.div`
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 32px 16px 0 16px;
  border-color: ${theme.colors.primary} transparent transparent transparent;
  margin: 0 auto;
`;

const ScrollArrow = props => (
  <Wrapper>
    <Link to={props.target} smooth>
      <Text color="white" textAlign="center" mb="1rem" letterSpacing="2px">
        DESLIZAR
      </Text>
      <Triangle />
    </Link>
  </Wrapper>
);

ScrollArrow.propTypes = {
  target: PropTypes.string,
};

export default ScrollArrow;
