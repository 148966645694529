import React, { Component } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import YoutubePlayer from 'react-youtube-player';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import youtube from '@fortawesome/fontawesome-free-brands/faYoutube';

import Thumbnail from './Thumbnail';
import { Grid, Text } from '../../styled';

const Wrapper = styled.div`
  height: 240px;
  margin-bottom: 2rem;

  @media (min-width: 768px) {
    height: 440px;
  }
`;

class VideoPlayer extends Component {
  state = {
    activeVideo: this.props.videos.slice(0, 1).shift(),
  }

  setActiveVideo = video => {
    this.setState({
      activeVideo: video,
    });
  }

  render() {
    const { videos } = this.props;

    return (
      <>
        <Wrapper>
          <YoutubePlayer
              videoId={this.state.activeVideo.node.videoId}
              playbackState='unstarted'
              configuration={
                  {
                      showinfo: 0,
                  }
              }
          />
        </Wrapper>
        <Grid columns={4} overflow="scroll">
          {videos.map(video => <Thumbnail key={video.node.id} video={video} onClick={this.setActiveVideo} />)}
        </Grid>
        <Text color="gray" mt="2rem">
          <FontAwesomeIcon icon={youtube} />
          &nbsp;
          <a href="https://www.youtube.com/user/EddyHerreraOficial" target="_blank" rel="nofollow noopener">Ver más videos en YouTube &raquo;</a>
        </Text>
      </>
    )
  }
};

export default VideoPlayer;