import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  cursor: pointer;
  height: 80px;
  width: auto;

  img {
    width: auto;
    height: 100%;
  }

  @media (min-width: 768px) {
    height: 140px;
    overflow: hidden;
  }
`;

const Thumbnail = ({ video, onClick }) => (
  <Wrapper onClick={() => onClick(video)}>
    <img src={video.node.thumbnail.url} alt={video.node.title} />
  </Wrapper>
);

export default Thumbnail;
